/**
 * COMPONENT - Disclosure
 *
 * Display page disclosures inside footer section based on a state list
 */
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ContentBlockOverlay from "../content-block-overlay";
import { useSSOValues } from "../../../hooks/use-sso-values";
import { DISCLOSURES, DisclosureTypeKey } from "./disclosures-content";
import { Trans } from "react-i18next";
import { BackLink } from "./disclosure-link";
import {
  getTermsVersions,
  isMajorVersionDifferent,
} from "../../../helpers/utils";

const Disclosures = () => {
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.user);
  const { showSSOBanner } = useSelector((state: RootState) => state.ssoUser);

  const [activeDisclosures, setActiveDisclosures] = useState<
    DisclosureTypeKey[]
  >([]);
  const { disclosures } = useSelector((state: RootState) => state.disclosures);
  const { isPendingFastlaneAccount } = useSSOValues();

  const { userTermsVersion, latestTermsVersion } = getTermsVersions(user);

  const showTermsAndConditionsModal =
    !isPendingFastlaneAccount &&
    user &&
    isMajorVersionDifferent(latestTermsVersion, userTermsVersion);

  useEffect(() => {
    if (disclosures) {
      const activeDisclosures = Object.keys(DISCLOSURES).filter(
        (disclosure) => {
          const disclosureType = disclosure as DisclosureTypeKey;

          return (
            disclosures.includes(disclosureType) &&
            DISCLOSURES[disclosureType].activePath === location.pathname
          );
        }
      );
      setActiveDisclosures(activeDisclosures as DisclosureTypeKey[]);
    }
  }, [disclosures, location]);

  return (
    <ContentBlockOverlay
      shouldBlockContent={showTermsAndConditionsModal || showSSOBanner}
    >
      {activeDisclosures && activeDisclosures.length > 0 && (
        <div className="bg-white border-top">
          <div id="disclosure-content" className="container py-4 py-md-6">
            {activeDisclosures.map((activeDisclosure) => (
              <p
                key={activeDisclosure}
                className="mt-0 text-typography-surface-medium-emphasis typeface-body-fine"
              >
                <Trans
                  i18nKey={DISCLOSURES[activeDisclosure].translationKey}
                  components={{
                    backLink: <BackLink disclosureType={activeDisclosure} />,
                    br: <br />,
                    ...DISCLOSURES[activeDisclosure]?.components,
                  }}
                />
              </p>
            ))}
          </div>
        </div>
      )}
    </ContentBlockOverlay>
  );
};

export default Disclosures;
