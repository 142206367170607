import { Navigate, Outlet } from "react-router-dom";
import { cashOutPages } from "../../helpers/pages";
import Footer from "./footer";
import Toasts from "./toasts";
import Banners from "./banners";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";
import SSOBanner from "../../atomic/organisms/sso-banner";
import ContentBlockOverlay from "./content-block-overlay";
import { CASH_OUT_SESSION_STORAGE_KEYS } from "../../helpers/constants";
import { useState } from "react";
import { getCashOutFullPath, shouldShowProfilePage } from "../../helpers/utils";
import GenericBaseModal from "../../atomic/organisms/modal/generic-base-modal";
import Header from "./header";
import InitialTermsConditions from "./initial-terms-conditions";

type Props = {
  className?: string;
  displayAuthInfo: boolean;
};

const Layout = ({ className = "", displayAuthInfo }: Props) => {
  const [shouldBlockContent, setShouldBlockContent] = useState(false);
  const { user } = useSelector((state: RootState) => state.user);
  const modalState = useSelector((state: RootState) => state.modalState);

  /** if started the cash-out flow process and signed in, redirect back to the cash-out flow */
  if (
    sessionStorage.getItem(CASH_OUT_SESSION_STORAGE_KEYS.SIGN_IN) === "true"
  ) {
    return <Navigate to={getCashOutFullPath(cashOutPages.steps.signIn)} />;
  }

  return (
    <main className={className}>
      <Header
        displayAuthInfo={displayAuthInfo}
        showProfileLink={shouldShowProfilePage(user)}
      />
      <InitialTermsConditions
        setShouldBlockContent={(value) => setShouldBlockContent(value)}
      />
      <Banners />
      <SSOBanner />
      <div className="flex-grow-1 bg-white position-relative">
        <ContentBlockOverlay
          className="h-100"
          shouldBlockContent={shouldBlockContent}
        >
          <Outlet />
        </ContentBlockOverlay>
      </div>
      <Footer />
      <Toasts />
      <GenericBaseModal {...modalState} />
    </main>
  );
};

export default Layout;
