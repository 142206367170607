import mixpanel, { Dict } from "mixpanel-browser";
import { registerSession, unregisterSession } from "../hooks/use-session";
import {
  generateNewSession,
  getSessionId,
  MIXPANEL_EVENTS,
  removeSessionId,
  removeSessionTimestamp,
} from "analytics";
import cleanCashOutFlow from "../modules/cash-out-loan-quote/helpers/clean-cash-out-flow";
import { DEEP_LINKING_CURRENT_HASH_ID } from "./constants";

// We are using Auth0 URL instead of SDK because the SDK use PKCE flow (Proof Key for Code Exchange)
// that is incompatible with the backend api integration
export const AUTH0_URL =
  `${process.env.REACT_APP_AUTH0_CLIENT_DOMAIN}/authorize` +
  `?response_type=code` +
  `&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}` +
  `&audience=${process.env.REACT_APP_AUTH0_AUDIENCE}`;

export const LOGOUT_REDIRECT = `${process.env.REACT_APP_API_DOMAIN}/logout?returnTo=${process.env.REACT_APP_DOMAIN}`;

export type LoginQueryParams =
  | "ext-account-created"
  | "ext-email-verification"
  | "ext-heloan-digital-account";

/**
 * Handles the login process and optionally displays a success banner to the user.
 *
 * @function
 * @param {LoginQueryParams} [queryParams] - A specific query parameter to be added to the login URL.
 *   * "ext-account-created" is used to indicate that a success banner should be displayed
 *     to notify the user that their account has been successfully created.
 *   * "ext-email-verification" is used to indicate that the user did complete their email
 *     validation process
 * @param {Dict} [mixpanelProperties] - Additional properties to be sent with the Mixpanel event.
 *   The `button` property is used to identify the login button click event.
 *
 */
export const handleLogIn = (
  queryParams?: LoginQueryParams,
  mixpanelProperties?: Dict
) => {
  const currentMixpanelProperties = mixpanelProperties ?? {
    button: "login",
    queryParams,
  };

  // Send to mixpanel an event every time the user clicks on login button
  mixpanel.track(MIXPANEL_EVENTS.CLICK, currentMixpanelProperties);

  const extraQueryParams = queryParams ? { [queryParams]: "true" } : undefined;

  window.location.href = buildAuth0Url(extraQueryParams);
};

export const handleLogOut = async () => {
  try {
    // Clean Cash Out Loan Quote Flow
    cleanCashOutFlow();
    // Remove user related data from the monitoring and tracking services
    await unregisterSession();
    // Remove session ID and session timestamp
    removeSessionId();
    removeSessionTimestamp();
    window.location.href = LOGOUT_REDIRECT;
  } catch (err) {
    console.error(err);
  }
};

export const buildAuth0Url = (
  extraQueryParams?: Record<string, string>
): string => {
  const savedSessionId = getSessionId();

  const state = savedSessionId ?? generateNewSession();

  // if we had to generate a new session, register it in the services
  if (!savedSessionId) {
    registerSession(state);
  }

  const buildQueryParams = extraQueryParams
    ? `&${new URLSearchParams(extraQueryParams).toString()}`
    : "";

  // We will validate if there is a deep linking hash id present
  const hashId = sessionStorage.getItem(DEEP_LINKING_CURRENT_HASH_ID);

  let redirectUrl = `&redirect_uri=${process.env.REACT_APP_AUTH0_REDIRECT_URI}`;

  if (hashId) {
    redirectUrl = `${redirectUrl}?redirect_id=${hashId}`;
    sessionStorage.removeItem(DEEP_LINKING_CURRENT_HASH_ID);
  }

  return `${AUTH0_URL}&state=${state}${buildQueryParams}${redirectUrl}`;
};
