import { useGate } from "statsig-react";

/**
 * List of feature gates used in the project
 */
export const FEATURE_GATES = {
  TAX_SEASON_NOTIFICATION: "1098-tax-season-notification", // permanent
  ENABLE_NEXT_LHIS_CTA: "enable_next_lhis_cta",
  CASH_OUT_REFINANCE_LOAN_QUOTE: "cash_out_refi_loan_quote",
  ENABLE_MULTIPLE_LOAN_MOCKS: "enable_multiple_loan_mocks",
  ENABLE_MULTIPLE_LOANS: "enable_multiple_loans",
  ENABLE_NEXT_STEPS_MODULE: "enable_next_steps_module",
  BOLT_INSURANCE_AD: "bolt-insurance-ad",
  ENABLE_DIGITAL_ACCOUNT_CREATION: "enable_digital_account_creation",
  ENABLE_TAVANT_CRUISE_NEXT_STEPS: "enable_tavant_cruise_next_steps",
  ENABLE_SUBSERVICER_REDIRECT_TARGET_PARAMS:
    "enable_subservicer_redirect_target_params",
  ENABLE_KUKUN_COST_CALCULATOR: "enable_kukun_cost_calculator",
  ENABLE_TAVANT_DIGITAL_HELOAN: "enable_tavant_digital_heloan",
  ENABLE_OFFERS: "enable_offers",
  ENABLE_LAKEVIEW_UNAUTH_UI_REFRESH: "enable_lakeview_unauth_ui_refresh",
  ENABLE_FLOATING_BUTTON_MORTGAGE_PAY: "enable_floating_button_mortgage_pay",
};

export type FeatureGateConfig = Partial<{
  [key in keyof typeof FEATURE_GATES]: boolean;
}>;

/**
 * Wrapper around the useGate functionality
 * Won't return the value of the feature gate until the SDK is completely initialized
 */
export const useFeatureGate = (gateName: string): boolean => {
  const { value, isLoading } = useGate(gateName);

  return isLoading ? false : value;
};
